import { Text, Image, ImageFit, Link } from '@fluentui/react';


interface SelectionButtonProps {
    icon?: string,
    text: string,
    fontSize?: number,
    iconHeight?: number,
    disabled?: boolean,
    onClick: () => void,
    children?: React.ReactNode
}


export const SelectionButton = (props: SelectionButtonProps) => {
    const linkStyles = {
        root: {
            fontSize: 16,
            isButton: true,
            backgroundColor: props.disabled ? "#e1e1e1" : "white",
            textAlign: "center",
            width: "100%",
            height: 100,
            marginBottom: 17,
            marginLeft: 12,
            marginRight: 12,
            borderRadius: 12,
            boxShadow: props.disabled ? "none" : "0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
            selectors: {
                ":hover": {
                    textDecoration: "none",
                },
                ":active:hover": {
                    textDecoration: "none",
                }
            }
        }
    };

    let content = null;
    if (props.children !== undefined) {
        content = props.children;
    } else if (props.icon !== undefined) {
        const iconStyles = {
            root: {
                height: props.iconHeight,
                width: "100%"
            },
            image: {
                height: "100%"
            }
        };
        content = (
            <Image src={props.icon} imageFit={ImageFit.center} alt={props.text} styles={iconStyles} />
        );
    } else {
        const textStyles = {
            root: {
                fontSize: props.fontSize ?? 18,
            }
        };
        content = (
            <Text styles={textStyles}>
                {props.text}
            </Text>
        );
    }

    return (
        <Link onClick={props.onClick} styles={linkStyles}>
            {content}
        </Link>
    );
}