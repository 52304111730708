import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Stack, Text, Image, ImageFit, List, } from '@fluentui/react';
import { Trans, useTranslation } from 'react-i18next';

import { productTypeNames } from '@src/constants';
import computersSvg from '@src/resources/computers.svg'

import { SelectionButton } from './selectionButton';


export const ProductTypeSelectionView = () => {
    const { productGroup } = useParams();
    const { t } = useTranslation();
    let navigate = useNavigate();
    const selectWindows = useCallback(() => navigate(productTypeNames.windows), [navigate]);
    // We don't support mac anymore
    const selectMac = useCallback(() => { }, []);

    useEffect(() => {
        if (productGroup === productTypeNames.windows) {
            // Windows productGroup can only have windows as productType
            navigate(productTypeNames.windows, { replace: true });
        }
    }, [productGroup, selectWindows])

    if (productGroup === productTypeNames.windows) {
        return null;
    }

    const containerStyles = {
        root: {
            paddingLeft: 15,
            paddingRight: 15,
        }
    };
    const headerStyles = {
        root: {
            fontSize: 24,
            fontWeight: "700",
            textAlign: "center",
            marginTop: 3,
            marginBottom: 3
        }
    };
    const imageStyles = {
        root: {
            height: 119,
            width: "100%",
        },
        image: {
            height: 69,
        }
    };
    const textStyles = {
        root: {
            fontSize: 16,
            marginBottom: 30,
            width: "100%",
            textAlign: "left",
            paddingLeft: 15,
            marginButtom: 30,
        }
    };
    const macTextStyles = {
        root: {
            fontSize: 38,
            color: "#606060"
        }
    };
    const macSubTextStyles = {
        root: {
            fontSize: 14,
            fontStyle: "italic",
            color: "#808080"
        }
    };

    return (
        <Stack horizontalAlign="center" styles={containerStyles}>
            <Text as="h1" styles={headerStyles}>
                {t("productTypeSelection.title", "Microsoft Product Activation")}
            </Text>
            <Image src={computersSvg} imageFit={ImageFit.centerContain} styles={imageStyles} alt="Computers Icon." />
            <Text styles={textStyles}>
                <Trans i18nKey="productTypeSelection.selectType">
                    Select your <b>Operating System</b>:
                </Trans>
            </Text>
            <SelectionButton
                text={t("productTypeSelection.windows", "Windows")}
                fontSize={38}
                onClick={selectWindows}
            />
            <SelectionButton
                text={t("productTypeSelection.windowsServer", "Windows Server")}
                fontSize={38}
                onClick={selectWindows}
            />
            <SelectionButton
                text={t("productTypeSelection.macOS", "Mac OS")}
                fontSize={38}
                onClick={selectMac}
                disabled
            >
                <Text styles={macTextStyles}>
                    {t("productTypeSelection.macOS", "Mac OS")}
                </Text>
                <br/>
                <Text styles={macSubTextStyles}>
                    {t("productTypeSelection.macOSHint", "(Activation not currently supported in this application)")}
                </Text>
            </SelectionButton>
        </Stack>
    );
}