import { Icon, List, mergeStyleSets } from '@fluentui/react';
import { useCallback } from 'react';

export interface SelectionListItem {
    id?: string,
    name: string,
    action?: () => void
}

interface ListItemProps {
    item: SelectionListItem,
    onSelect: (id?: string) => void
}

interface SelectionListProps {
    items: SelectionListItem[],
    onSelect: (id?: string) => void
}

const listItemClassNames = mergeStyleSets({
    itemCell: {
        boxSizing: "border-box",
        minHeight: 48,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottom: `1px solid #E1E1E1`,
        display: 'flex',
        alignItems: 'center',
        background: "#FFFFFF",
        selectors: {
            '&:hover': { background: "#F1F1F1", cursor: "pointer" },
        },
    },
    text: {
        fontWeight: 400,
        fontSize: 17,
        color: "#0078D4",
        flexGrow: 1
    },
    chevron: {
        alignSelf: 'center',
        marginLeft: 10,
        color: "#212121",
        fontSize: 12,
        fontWeight: 800,
        flexShrink: 0,
    },
});
const ListItem = (props: ListItemProps): JSX.Element => {
    const { item, onSelect } = props;

    const onClick = useCallback(() => {
        if (item.action !== undefined) {
            item.action();
        } else {
            onSelect(item.id);
        }
    }, [item, onSelect]);

    return (
        <div data-is-focusable={true} className={listItemClassNames.itemCell} onClick={onClick} role="link" tabIndex={0} onKeyUp={function(e) {
            if (e.key === "Enter") {
                (document.activeElement as HTMLElement).click();
            }
        }} >
            <div className={listItemClassNames.text} role="link">
                {item.name}
            </div>
            <Icon iconName="ChevronRight" className={listItemClassNames.chevron} />
        </div>
    );
}

export const SelectionList = (props: SelectionListProps) => {
    const { items, onSelect } = props;
    const onRenderListItem = useCallback((item: SelectionListItem) => {
        return (
            <ListItem item={item} onSelect={onSelect} />
        );
    }, [onSelect]);
    return <List items={items} onRenderCell={onRenderListItem} />;
}