import { Routes, Route, Location } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Spinner, Stack } from '@fluentui/react';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import i18n from 'i18next';

import { ProductActivationPage, ProductActivationPageRoutes } from "pages/ProductActivation";
import { ProductSelectionPage, ProductSelectionPageRoutes } from "pages/ProductSelection";
import { ContactSupportPage, ContactPageRoutes } from "pages/ContactSupport";
import { FeedbackPage, FeedbackPageRoutes } from "pages/Feedback";
import { ErrorPage } from "./pages/Error";
import { HelpPage, HelpPageRoutes } from "./pages/Help";
import { NavBar, NavigateWithUrlId, PrivacyLink } from 'components';
import { Outlet, useLocation } from "react-router-dom";
import { NavBarProps } from "./components/NavBar";
import { useAppActions, useAppState, WithAppState } from "./states";
import { connectToAppinsight } from "./appInsights";

const LandingPage = () => {
    return <NavigateWithUrlId to="/select" />;
}

const getNavBarProps = (location: Location): NavBarProps => {
    const pathname = location.pathname;
    const idLength = pathname.split("/")[1].length
    const pathWithoutId = pathname.slice(idLength + 1);

    if (pathWithoutId === "") {
        return { whiteBackground: false, buttons: false, back: false };
    }
    if (pathWithoutId.startsWith("/select") && !pathWithoutId.endsWith("Unspecified")) {
        return { whiteBackground: false, buttons: false, back: false };
    }
    if (pathWithoutId.startsWith("/selfhelp") && !pathname.includes("/selfhelp/")) {
        return { whiteBackground: true, buttons: true, back: true };
    }
    if (pathWithoutId.startsWith("/error")) {
        return { whiteBackground: true, buttons: false, back: false };
    }
    if (pathWithoutId.startsWith("/rate-us")) {
        if (pathWithoutId.endsWith("/cancel")) {
            return { whiteBackground: true, buttons: false, back: false };
        }
        else {
            return { whiteBackground: false, buttons: false, back: false };
        }
    }
    if (pathWithoutId.startsWith("/contact")) {
        if (pathWithoutId.endsWith("/contact")) {
            return { whiteBackground: true, buttons: false, back: false };
        }
        else {
            return { whiteBackground: false, buttons: false, back: false };
        }
    }
    if (pathWithoutId.startsWith("/rate-us") && !pathname.endsWith("cancel")) {
        return { whiteBackground: false, buttons: false, back: false };
    }
    if (pathWithoutId.startsWith("/rate-us") && pathname.endsWith("cancel")) {
        return { whiteBackground: true, buttons: false, back: false };
    }
    if (pathWithoutId.endsWith("/confirmation")) {
        return { whiteBackground: true, buttons: false, back: false };
    }
    if (pathWithoutId.startsWith("/activate") && pathWithoutId !== "/activate") {
        return { whiteBackground: true, buttons: false, back: false };
    }

    return { whiteBackground: true, buttons: true, back: false };
}

interface PathInfo {
    isErrorPage: boolean
    isActivationSuccessPage: boolean
    isFeedbackPage: boolean
    isThankYouPage: boolean
    isContactPage: boolean
    isCallbackScheduledPage: boolean
    isTryAgainPage: boolean
}

const getPathInfo = (path: string): PathInfo | undefined => {
    const realPath = path.split("/").slice(2).join("/");
    if (realPath === undefined) {
        return undefined;
    }
    return {
        isErrorPage: realPath.startsWith("error"),
        isActivationSuccessPage: realPath.startsWith("activate/result/success"),
        isFeedbackPage: realPath.startsWith("rate-us"),
        isThankYouPage: realPath.startsWith("rate-us/thank-you"),
        isContactPage: realPath.startsWith("contact"),
        isCallbackScheduledPage: realPath.startsWith("contact/callback"),
        isTryAgainPage: realPath.startsWith("contact/try-again"),
    }
}

const PageContainer = () => {
    useEffect(() => initializeIcons(), []);
    const { urlId, errorType, callbackEstimatedWaitMinutes, hasFeedbackSubmitted, checksumCount, appInsightConnectionString, selfHelpActivated, callbackErrorReached } = useAppState();
    const { reloadUrlInfo } = useAppActions();
    const [appInsights, setAppInsights] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navBarProps = useMemo(() => getNavBarProps(location), [location]);

    useEffect(() => {
        if (appInsightConnectionString) {
            const { reactPlugin, appInsights } = connectToAppinsight(appInsightConnectionString, urlId);
            setAppInsights(reactPlugin);
            reactPlugin.trackEvent({
                name: "PageLoaded",
                properties: {
                    browserLanguage: navigator?.language,
                    resolvedLanguage: i18n.resolvedLanguage
                }
            });
            const reportTabClose = () => {
                reactPlugin.trackEvent({ name: "BrowserWindowUnload" });
                appInsights.onunloadFlush();
            };
            window.addEventListener("beforeunload", reportTabClose);
            return () => {
                appInsights.unload();
                window.removeEventListener("beforeunload", reportTabClose);
            };
        }
    }, [appInsightConnectionString, setAppInsights, urlId]);

    useEffect(() => {
        const pathSegments = location.pathname.split("/").slice(1);
        let recordUrlHit = false;
        if (pathSegments.length == 1 || pathSegments[2] === "") {
            // User lands on the landing page directly
            recordUrlHit = true;
        }
        reloadUrlInfo(recordUrlHit).then(() => { setIsLoading(false); });
    }, []);

    const containerStyles = {
        root: {
            minHeight: "100vh"
        }
    };
    if (isLoading) {
        return <Stack verticalAlign="center" horizontalAlign="center" styles={containerStyles}>
            <Spinner size={3} />
        </Stack>;
    }
    const pathInfo = getPathInfo(location.pathname);
    const errorPathException = 'noFurtherSupport';
    if (pathInfo) {
        if ((errorType !== undefined && errorType !== errorPathException) && !pathInfo.isErrorPage) {
            return <NavigateWithUrlId to={`/error/${errorType}`} replace />;
        }
        if (selfHelpActivated && !(pathInfo.isActivationSuccessPage || pathInfo.isFeedbackPage)) {
            return <NavigateWithUrlId to={`/activate/result/success`} replace />;
        }
        if (hasFeedbackSubmitted && !pathInfo.isThankYouPage) {
            return <NavigateWithUrlId to={`/rate-us/thank-you`} replace />;
        }
        if (callbackEstimatedWaitMinutes !== undefined && !(pathInfo.isCallbackScheduledPage || pathInfo.isFeedbackPage)) {
            return <NavigateWithUrlId to={`/contact/callback`} replace />;
        }
        if (checksumCount >= 3 && !(pathInfo.isContactPage || pathInfo.isFeedbackPage)) {
            return <NavigateWithUrlId to="/contact" />;
        }
        if (callbackErrorReached && !pathInfo.isTryAgainPage) {
            return <NavigateWithUrlId to={'/contact/try-again'} replace/>;
        }
    }

    const contentStyles = {
        root: {
            position: "relative" as "relative"
        }
    };
    return (
        <AppInsightsContext.Provider value={appInsights}>
            <Stack verticalFill styles={containerStyles}>
                <NavBar {...navBarProps} />
                <Stack.Item grow={1} styles={contentStyles}>
                    <Outlet />
                </Stack.Item>
                <Stack.Item align="center">
                    <PrivacyLink />
                </Stack.Item>
            </Stack>
        </AppInsightsContext.Provider>
    );
}

function App() {
    return (
        <Routes>
            <Route path=":urlId" element={<WithAppState><PageContainer /></WithAppState>}>
                <Route index element={<LandingPage />} />
                <Route path="select" element={<ProductSelectionPage />}>
                    {ProductSelectionPageRoutes}
                </Route>
                <Route path="activate" element={<ProductActivationPage />}>
                    {ProductActivationPageRoutes}
                </Route>
                <Route path="contact" element={<ContactSupportPage />}>
                    {ContactPageRoutes}
                </Route>
                <Route path="rate-us" element={<FeedbackPage />} >
                    {FeedbackPageRoutes}
                </Route>
                <Route path="selfhelp" element={<HelpPage />} >
                    {HelpPageRoutes}
                </Route>
                <Route path="error/:errorType" element={<ErrorPage />} />
                <Route path="*" element={<NavigateWithUrlId to="/select" />} />
            </Route>
        </Routes>
    );
}

export default App;
