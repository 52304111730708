import { useCallback } from 'react';
import { useParams, Navigate } from "react-router-dom";
import { Stack, Text, Image, ImageFit, Spinner } from '@fluentui/react';
import i18next from '@src/i18n';
import { useTranslation } from 'react-i18next';

import { productGroupNames, unspecifiedProductNameSuffix } from '@src/constants';
import { NavigateWithUrlId, SelectionList, SelectionListItem } from '@src/components';
import { useAppActions, useAppState } from '@src/states';
import { updateSelectedProducts, ProductInfo, useApiRequest } from '@src/data';
import windowsIcon from '@src/resources/windows_icon.png'
import officeIcon from '@src/resources/office_icon.png'

import { useProductInfo } from './productInfoContext';


const ProductGroupIcon = (props) => {
    const { productGroup } = props;

    let icon = null;
    let height = 0;
    if (productGroup === productGroupNames.windows) {
        icon = windowsIcon;
        height = 50;
    } else if (productGroup === productGroupNames.office) {
        icon = officeIcon;
        height = 32;
    }

    const iconStyles = {
        root: {
            height: height,
            width: "100%",
            marginTop: 10,
        },
        image: {
            height: height,
        }
    };
    return <Image src={icon} imageFit={ImageFit.center} alt={productGroup} styles={iconStyles} />;
}


const getProductOptionsToShow = (allProducts: ProductInfo[], productGroup: string, productType: string): SelectionListItem[] => {
    let applicableProducts = allProducts.filter(p => p.productType.toLowerCase() === productType && p.productGroup.toLowerCase() === productGroup);
    let unspecifiedProduct = applicableProducts.filter(p => p.productName.endsWith(unspecifiedProductNameSuffix)).slice(0, 1);
    let concreteProducts = applicableProducts.filter(p => !p.productName.endsWith(unspecifiedProductNameSuffix));
    concreteProducts.sort((p1, p2) => p2.productLaunchOrder - p1.productLaunchOrder);

    let concreteOptions = concreteProducts.map(p => { return { id: p.productName, name: p.productName }; });
    let unspecifiedOptions = unspecifiedProduct.map(p => { return { id: p.productName, name: i18next.t("productNameSelection.versionUnknownButton", "I don't know") }; });
    return [...concreteOptions, ...unspecifiedOptions];
}


const useProductSelectedCallback = () => {
    const { urlId } = useAppState();
    const { productGroup, productType } = useParams();
    const products = useProductInfo();
    const { setSelectedProduct, navigateTo } = useAppActions();
    const updateSelectedProductsAPI = useApiRequest(updateSelectedProducts, true);

    return useCallback((productName: string) => {
        if (productName.endsWith(unspecifiedProductNameSuffix)) {
            navigateTo(`/select/${productGroup}/${productType}/${productName}`);
            return;
        }
        for (let product of products) {
            if (product.productName === productName && product.productGroup.toLowerCase() === productGroup && product.productType.toLowerCase() === productType) {
                setSelectedProduct(product);
                updateSelectedProductsAPI.call(
                    urlId,
                    {
                        productName: product.productName,
                        productType: product.productType,
                        productGroup: product.productGroup,
                        numberOfDigits: product.numberOfDigits
                    }
                );
                navigateTo(`/activate`);
                return;
            }
        }
        // TODO throw error
    }, [navigateTo, productGroup, productType, products, setSelectedProduct, updateSelectedProductsAPI, urlId]);
}


export const ProductNameSelectionView = () => {
    let { productGroup, productType } = useParams();
    const { t } = useTranslation();
    const onProductSelected = useProductSelectedCallback();
    const products = useProductInfo();

    if (products === null) {
        const containerStyles = {
            root: {
                minHeight: "100vh"
            }
        };
        return <Stack verticalAlign="center" horizontalAlign="center" styles={containerStyles}>
            <Spinner size={3} />
        </Stack>;
    }
    if (productGroup === productGroupNames.unspecified) {
        return <NavigateWithUrlId to={unspecifiedProductNameSuffix} replace />;
    }

    let options = getProductOptionsToShow(products, productGroup, productType);
    if (options.length === 0) {
        return <Navigate to={productGroupNames.unspecified} replace={true} />;
    }

    const headerStyles = {
        root: {
            fontSize: 13,
            fontWeight: "500",
            textAlign: "center",
            color: "#6E6E6E",
            marginTop: 21,
            marginBottom: 14,
            marginLeft: 16,
            display: "inline-block"
        }
    };
    const groupDisplayName = productGroup.charAt(0).toUpperCase() + productGroup.slice(1);

    return (
        <div>
            <ProductGroupIcon productGroup={productGroup} />
            <Text as="h1" styles={headerStyles}>
                {t("productNameSelection.selectVersion", "Select {{groupDisplayName}} version", {groupDisplayName}) }
            </Text>
            <SelectionList items={options} onSelect={onProductSelected} />
        </div>
    );
}
